<template>
  <div style="width: 100%;">
    <v-app>
      <div style="margin: 20px 0 0 20px; width: 150px;"><v-select outlined dense placeholder="Select class" :items="classes" v-model="classe"></v-select></div>
      <div style="margin: 20px 0 0 70px; width: 180px; position: absolute; top: 0; left: 110px;"><v-select outlined dense placeholder="Choose academic year" :items="academicYear" v-model="academic" @change="fetchCourseRecords(), lightup = true"></v-select></div>
      <hr style="margin: 0 20px 0 20px; color: #0a43ae;">
      <v-data-table
        :headers="headers"
        :items="courses"
        :loading="loading"
        sort-by="calories"
        class="elevation-1"
        v-if="lightup"
      >
        <template v-slot:item.id="{item}">
          <v-chip small :color="`#9c9c9c`" style="color: white; font-weight: 600; width: 25px; padding: 8px;">
            {{courses.map(function(x) {return x.id; }).indexOf(item.id)+1}}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon :color="'#0a43ae'" @click="deleteCourse(item.id,item.title,item.course_id)">delete</v-icon>
<!--          <i class="fa fa-trash-o" aria-hidden="true" style="color: #0a43ae" @click="deleteCourse(item.id,item.title,item.course_id)"></i>-->
        </template>
        <template v-slot:item.title="{ item }">
          <input type="text" style="width: 100%;" :value="item.title" :id="'title'+item.id" @dblclick="callModal(item.id,'title')" @blur="blurMode(item.id,'title')" @keyup.enter="blurMode(item.id,'title')"  readonly/>
        </template>
        <template v-slot:item.semester="{ item }">
          <input type="text" :value="item.semester" :id="'semester'+item.id" @dblclick="callModal(item.id,'semester')" @blur="blurMode(item.id,'semester')"  readonly/>
        </template>
        <template v-slot:item.names="{ item }">
          <input type="text" :value="item.names" :id="'names'+item.id" @dblclick="callModal(item.id,'names')" @blur="blurMode(item.id,'names')"  readonly/>
        </template>
        <template v-slot:item.points="{ item }">
          <input type="text" :value="item.points" :id="'points'+item.id" @dblclick="editMode(item.id,'points')" @blur="blurMode(item.id,'points')" @keyup.enter="blurMode(item.id,'points')"  readonly/>
        </template>
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Courses</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mt-2"
            @click="edit(item),state='edit'"
          >
          </v-icon>
        </template>
      </v-data-table>
<!--      ========== Lecture modal======-->
      <v-dialog
        v-model="modal2"
        max-width="500px"
        :persistent="true"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change Lecture</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    v-model="mentor"
                    label="select lecturer"
                    name="Lecturer"
                    v-validate="'required'"
                    :items="mentors"
                    @click="fetchSelectedMentors"
                  ></v-select>
                  <span style="color: red;"
                    v-show="errors.has('Lecturer')">{{ errors.first('Lecturer') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="quitModal2(modal2 = false)"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="edit('lecture_id')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
<!--      ========= end lecture modal =======-->
<!--      =========class modal=========-->
      <v-dialog
        v-model="modal1"
        max-width="500px"
        :persistent="true"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change Class</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    v-model="classe"
                    label="select lecturer"
                    name="Lecturer"
                    v-validate="'required'"
                    :items="classes"
                    @click="fetchSelectedClass"
                  ></v-select>
                  <span style="color: red;"
                    v-show="errors.has('Lecturer')">{{ errors.first('Lecturer') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="quitModal2(modal2 = false)"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="edit('class_id')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
<!--      =========== end class modal ============-->
<!--      =========course modal ==========-->
      <v-dialog
        v-model="modal3"
        max-width="500px"
        :persistent="true"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change Semester</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    v-model="semester"
                    label="Choose Semester"
                    name="Lecturer"
                    v-validate="'required'"
                    :items="semesters"
                  ></v-select>
                  <span style="color: red;"
                    v-show="errors.has('Lecturer')">{{ errors.first('Lecturer') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="quitModal3()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="edit('semester')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
<!--      ======== end modal ===========-->
    </v-app>
    <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>
<script>
import {
  mdiAccount,
  mdiPencil,
  mdiShareVariant,
  mdiDelete,
} from '@mdi/js'
import NewCourse from './NewCourse'
import Swal from 'sweetalert2'
export default {
  components: {
    NewCourse,
  },
  data: () => ({
    icons: {
      mdiAccount,
      mdiPencil,
      mdiShareVariant,
      mdiDelete,
    },
    headers: [
      {
        text: '#',
        value: 'id',
      },
      {
        text: 'Course name',
        value: 'course',
      },
      {
        text: 'Class name',
        value: 'title',
      },
      {
        text: 'Lecturer',
        value: 'names',
      },
      {
        text: 'Semester',
        value: 'semester',
      },
      {
        text: 'Academic year',
        value: 'academic_year',
      },
      {
        text: '',
        value: 'action',
      },
    ],
    date: new Date().toISOString().substr(0, 7),
    modal: false,
    dialogModal: false,
    logger: [],
    header: '',
    courses: [],
    on: false,
    loading: false,
    AssignModal: false,
    classes: [],
    classe: null,
    semesters: [
      {
        text: 'Semester 1',
        value: 1,
      }, {
        text: 'Semester 2',
        value: 2,
      },
    ],
    semester: null,
    mentors: [],
    selectedCourses: [],
    lecturer: null,
    year: '',
    mentor: '',
    modal2: false,
    modal1: false,
    modal3: false,
    lectutureId: '',
    classId: '',
    semesterId: '',
    course: '',
    academicYear: [],
    academic: '',
    lightup: false,
  }),
  created () {
    this.fetchSelectedClasses()
    this.fetchAcademicYear()
  },
  methods: {
    quitModal3 () {
      this.modal3 = false
    },
    async fetchSelectedClasses () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_classes',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.classes = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.classes = []
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    callModal (id, key) {
      if (key === 'title') {
        this.classId = id
        this.modal1 = true
      } else if (key === 'semester') {
        this.semesterId = id
        this.modal3 = true
      } else if (key === 'names') {
        this.lectutureId = id
        this.modal2 = true
      }
    },
    quitModal2 () {
      this.modal2 = false
      this.mentor = ''
    },
    getYear () {
      const d = new Date()
      this.year = d.getFullYear()
    },
    editMode: function (courseId, key) {
      // alert(courseId + key)
      var course = document.getElementById(key + '' + courseId)
      course.focus()
      course.readOnly = false
      course.classList.add('editMode')
    },
    blurMode (courseId, key) {
      var course = document.getElementById(key + '' + courseId)
      course.readOnly = true
      course.classList.remove('editMode')
      this.editClass(courseId, key, course.value)
    },
    async edit (key) {
      let id = 0
      let value = ''
      if (key === 'lecture_id') {
        alert(key)
        id = this.lectutureId
        value = this.mentor
      } else if (key === 'class_id') {
        id = this.classId
        value = this.classe
      } else if (key === 'semester') {
        id = this.semesterId
        value = this.semester
      }
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: id,
        key: key,
        value: value,
      }
      try {
        const response = await this.axios({
          url: 'manipulate_course_records/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (response.status === 200) {
          this.fetchCourseRecords()
          if (key === 'lecture_id') {
            this.mentor = ''
            this.modal2 = false
          } else if (key === 'class_id') {
            this.classe = ''
            this.modal1 = false
          } else if (key === 'semester') {
            this.semester = ''
            this.modal3 = false
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else {
          console.log(e)
        }
      }
    },
    async fetchSelectedMentors () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_mentors',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.mentors = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async fetchSelectedClass () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_class',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.classes = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async fetchSelectedCourses () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'selectedCourse',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.selectedCourses = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.selectedCourses = []
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    async fetchAcademicYear () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'academic_year',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.academicYear = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.academicYear = []
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    async fetchCourseRecords () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_course_records',
          method: 'post',
          data: {
            cls: this.classe,
            academic: this.academic,
          },
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.lightup = true
          this.loading = false
          this.courses = res.data
          this.classe = ''
          this.academic = ''
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        }
        if (error.response && error.response.status === 400) {
          this.courses = []
          this.classe = ''
          this.academic = ''
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    deleteCourse (id, title, course) {
      this.$confirm(
        {
          message: 'Delete ' + ' ' + title + ' ' + 'from list ?',
          button: {
            no: 'No',
            yes: 'Yes',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: async confirm => {
            if (confirm) {
              // ... do something
              if (localStorage.getItem('logged_user')) {
                this.logger = JSON.parse(localStorage.getItem('logged_user'))
              }
              this.header = this.logger.accessToken
              try {
                const res = await this.axios({
                  url: 'delete_course_records',
                  method: 'post',
                  data: {
                    id: id,
                    course_id: course,
                  },
                  headers: {
                    Authorization: 'Bearer ' + this.header,
                  },
                })
                if (res.status === 200) {
                  this.courses = this.courses.filter(course => course.id !== id)
                  this.loading = false
                  this.fetchCourseRecords()
                  console.log(res.data)
                }
              } catch (error) {
                if (error.response && error.response.status === 401) {
                  this.$router.push({ name: 'login-page' })
                }
                if (error.response && error.response.status === 400) {
                  Swal.fire({
                    title: 'Error!',
                    text: error.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                  })
                  this.loading = false
                } else {
                  console.log(error)
                }
              }
            }
          },
        },
      )
    },
  },
}
</script>
<style scoped>
  .editMode {
    padding: 3px;
    background-color: #fdf9ff;
    font-size: 14px;
    border: 1px solid;
    border-radius: 3px;
  }
</style>
